import _ from "lodash";
import { domainUrl } from "src/network/networks";
export const updateNestedArray = (
  jobId,
  arr,
  key,
  searchValue,
  updateValue
) => {
  let data = arr?.map((item) => {
    if (item.type === "columns") {
      let columns = updateNestedArray(
        jobId,
        item.columns,
        key,
        searchValue,
        updateValue
      );
      return { ...item, columns };
    } else if (typeof item === "object" && item.components) {
      if (item.type === "datagrid" && item[key] === searchValue) {
        return { ...item, data: updateValue, defaultValue: updateValue };
      } else {
        let components = updateNestedArray(
          jobId,
          item.components,
          key,
          searchValue,
          updateValue
        );
        return { ...item, components };
      }
    } else if (Array.isArray(item)) {
      return updateNestedArray(jobId, item, key, searchValue, updateValue);
    } else if (item[key] === searchValue) {
      if (item.type === "htmlelement") {
        return { ...item, content: updateValue };
      } else if (item.type === "checkbox" || item.type === "radio") {
        return {
          ...item,
          checked: updateValue,
          defaultValue: updateValue,
          value: updateValue,
        };
      } else {
        return { ...item, defaultValue: updateValue };
      }
    } else {
      if (item.type === "file") {
        return {
          ...item,
          filePath: `${domainUrl}assets/storage/input/${jobId}/${item.name}`,
          value: updateValue,
        };
      } else {
        return item;
      }
    }
  });
  return data;
};

export const updateSQLDataInNestedArray = (
  arr,
  key,
  searchValue,
  updateValue
) => {
  let data = arr.map((item) => {
    if (item.type === "columns") {
      let columns = updateSQLDataInNestedArray(
        item.columns,
        key,
        searchValue,
        updateValue
      );
      return { ...item, columns };
    } else if (typeof item === "object" && item.components) {
      if (item.type === "datagrid" && item[key] === searchValue) {
        return { ...item, data: updateValue, defaultValue: updateValue };
      } else {
        let components = updateSQLDataInNestedArray(
          item.components,
          key,
          searchValue,
          updateValue
        );
        return { ...item, components };
      }
    } else if (Array.isArray(item)) {
      return updateSQLDataInNestedArray(item, key, searchValue, updateValue);
    } else if (item[key] === searchValue) {
      return { ...item, data: updateValue };
    } else {
      return item;
    }
  });
  return data;
};

export const updateAnyFieldInNestedArray = (
  arr,
  key,
  searchValue,
  updateValue,
  FieldName
) => {
  let data = arr?.map((item) => {
    if (item.type === "columns") {
      let columns = updateAnyFieldInNestedArray(
        item.columns,
        key,
        searchValue,
        updateValue,
        FieldName
      );
      return { ...item, columns };
    } else if (typeof item === "object" && item.components) {
      if (item.type === "datagrid" && item[key] === searchValue) {
        return { ...item, data: updateValue, [FieldName]: updateValue };
      } else {
        let components = updateAnyFieldInNestedArray(
          item.components,
          key,
          searchValue,
          updateValue,
          FieldName
        );
        return { ...item, components };
      }
    } else if (Array.isArray(item)) {
      return updateAnyFieldInNestedArray(
        item,
        key,
        searchValue,
        updateValue,
        FieldName
      );
    } else if (item[key] === searchValue) {
      if (item.type === "htmlelement") {
        return { ...item, [FieldName]: updateValue };
      } else if (item.type === "checkbox" || item.type === "radio") {
        return {
          ...item,
          [FieldName]: updateValue,
        };
      } else {
        return { ...item, [FieldName]: updateValue };
      }
    } else {
      return item;
    }
  });
  return data;
};
